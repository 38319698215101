<template>
  <div>           
    <b-row v-if="myFiles.listOne.length">
      <b-col md="6">          
        <app-timeline>               
          <app-timeline-item variant="warning" v-for="(file, index) in myFiles.listOne" :key="file.id">
            <hr v-if="index === 0">                  
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6>{{file.name.toUpperCase() }}</h6>                                    
              <b-badge variant="light-primary" class="mb-1 mt-0" > {{file.typename}} </b-badge>                   
            </div>
            <div class="short-description">
              <p>{{file.description}}</p>
            </div>
            
            <div class="d-flex flex-sm-row flex-column justify-content-between align-items-start">
              <p>
                <b-img :src="setIconFile(file.tipeFile)" height="auto" width="30" class="mr-1" />
                <b class="align-bottom">{{file.lastRoute}}</b>                
              </p>
              <div>
                <!-- <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" size="sm" variant="primary" > {{$t('Download file')}} </b-button> -->
                <a class="btn btn-sm btn-primary" :href="imgUrl.slice(0, -1) + file.fileRoute" target="_blank">{{$t('Download file')}}</a>
              </div>
            </div>
            <hr>
          </app-timeline-item>                                
        </app-timeline>
      </b-col>
      <b-col md="6">
        <app-timeline>               
          <app-timeline-item variant="warning" v-for="( file, index) in myFiles.listTwo" :key="file.id">
            <hr v-if="index === 0">                  
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6>{{file.title}}</h6>
              <b-badge variant="light-primary" class="mb-1 mt-0" > {{file.typename}} </b-badge>                                                                     
            </div>
            <div class="short-description">
              <p>{{file.description}}</p>
            </div>
            <div class="d-flex flex-sm-row flex-column justify-content-between align-items-start">
              <p>
                <b-img
                  :src="setIconFile(file.tipeFile)"                      
                  height="auto"
                  width="30"
                  class="mr-1"
                />
              <b class="align-bottom">{{file.lastRoute}}</b>                
            </p>
              <div>
                <a class="btn btn-sm btn-primary" :href="imgUrl.slice(0, -1) + file.fileRoute" target="_blank">{{$t('Download file')}}</a>                  
              </div>
            </div>
          <hr>                             
          </app-timeline-item>
        </app-timeline>
      </b-col>
    </b-row>
    <b-row v-else>
      <b-col md="12">
        <b-alert variant="danger" show class="mb-0">
            <div class="alert-body"><feather-icon icon="InfoIcon" class="mr-50" /> {{$t('No resources found')}} </div>
        </b-alert>
      </b-col>
    </b-row>         
  </div>
</template>

<script>

import { mapState } from 'vuex'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import Ripple from 'vue-ripple-directive'
import { VBToggle,  VBTooltip } from 'bootstrap-vue'

export default {
  components:{    
    AppTimeline,
    AppTimelineItem,
  },
  directives: { 'b-toggle': VBToggle, 'b-tooltip': VBTooltip, Ripple },
  data(){
    return {
      imgUrl: process.env.VUE_APP_IMG_SRC_API,                      
    }
  },
  computed:{
    ...mapState('owners',['myFiles']),
  },
  methods:{  
    setIconFile(fileType){
      let routeIcon = require('@/assets/images/icons/icon-file.png')

      if(fileType === 'imagen'){
        routeIcon = require('@/assets/images/icons/icon-image.png')
      }

      if(fileType === 'pdf'){
        routeIcon = require('@/assets/images/icons/icon-pdf.png')
      }
      if(fileType === 'video'){
        routeIcon = require('@/assets/images/icons/icon-video.png')
      }
      if(fileType === 'audio'){
        routeIcon = require('@/assets/images/icons/icon-audio.png')
      }
      if(fileType === 'word'){
        routeIcon = require('@/assets/images/icons/icon-word.jpg')
      }
      
      if(fileType === 'excel'){
        routeIcon = require('@/assets/images/icons/icon-excel.png')
      }

      if(fileType === 'ppt'){
        routeIcon = require('@/assets/images/icons/icon-ppt.png')
      }
      return routeIcon
    }
  },  
}
</script>
<style scoped>
.short-description {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

</style>